.boarding-pass {
  max-width: 450px;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(to bottom, #F2F1F5, #EFEEF1);
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.flight-info {
  position: relative;
  height: 100px;
  margin: 40px;
  padding: 30px;
  width: 80%;
}

.flight-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flight-map:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  border-top: 2px dashed #000;
  border-radius: 50% 50% 0 0;
  transform: translateY(15%);
}

.plane-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: flight var(--flight-duration, 5s) linear;
  animation-play-state: paused;
  animation-delay: calc(var(--flight-duration) * -1 * var(--flight-progress, 0));
}

.plane {
  position: absolute;
  left: -10px;
  top: calc(50% - 20px);
  width: 60px;
  height: 30px;
  background-image: url("airplane-pointer.svg");
  background-size: contain;
  background-repeat: no-repeat;  
}

@keyframes flight {
  0% {
    transform: translateX(0) translateY(0px);
  }
  25% {
    transform: translateX(25%) translateY(-25px);
  }
  50% {
    transform: translateX(50%) translateY(-35px);
  }
  75% {
    transform: translateX(75%) translateY(-25px);
  }
  100% {
    transform: translateX(100%) translateY(0px);
  }
}

.airports {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.airport {
  text-align: center;
}

.airport-code {
  font-size: 24px;
  font-weight: bold;
  padding-top: 40px;
  color: #66CC8A;
}

.boarding-info {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;
}

.passenger-info {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.info-label {
  color: gray;
  font-size: 14px;

}

.info-data {
  margin-top: 6px;
  font-weight: bold;

}

.airline-logo {
  background-image: url('airline-logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.airplane-image {
  background-image: url('boardingpass-plane.png');
  position: fixed;
  top: 10px;
  right: -60px;
  width: 400px;
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
}

.worldmap-image {
  background-image: url('worldmap.png');
  position: fixed;
  display: flex;
  margin-left: -20px;
  width: 450px;
  height: 450px;
  background-size: contain;
  background-repeat: no-repeat;
}
